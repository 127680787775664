import { useRef, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Product.css';

import stickerKiscut from '../images/products/sticker-kiscut.jpg';


function Sticker() {
  const productRef = useRef(null)

  const scrollToBottom = () => {
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (localStorage.getItem('navClick')) {
      scrollToBottom();
      localStorage.removeItem('navClick');
    }
  }, []);
  return (
    <div className="Product-container">
      <Container fluid="md">
        <Row>
          <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={stickerKiscut} alt='Sticker Kiscut' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Sticker Kiscut</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Sticker;
