import './App.css';

import Slider from './Slider';
import Navbar from './Navbar';

function App() {
  return (
      <div>
        <Navbar />
        <Slider />
      </div>
  );
}

export default App;
