import { useRef, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Product.css';

import photoBox from '../images/products/photo-box.png';
import photoCanvas from '../images/products/photo-canvas.jpg';
import photoBook from '../images/products/photo-photobook.jpg';
import photoFrame from '../images/products/photo-frame.jpg';


function Photo() {
  const productRef = useRef(null)

  const scrollToBottom = () => {
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (localStorage.getItem('navClick')) {
      scrollToBottom();
      localStorage.removeItem('navClick');
    }
  }, []);
  return (
    <div className="Product-container">
      <Container fluid="md">
        <Row>
          {/* <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={photoBox} alt='Photobox' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Photo Box</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col> */}
          <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={photoCanvas} alt='Canvas' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Canvas</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={photoBook} alt='Photobook' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Photo Book</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={photoFrame} alt='Frame' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Photo Frame</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Photo;
