import { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AboutUs from '../images/about-us.jpg';
import './AboutUs.css';

class About extends Component {
  render() {
    return (
      <div>
        <Container fluid="md" className="AboutUs-banner">
          <Row >
            <Col><img src={AboutUs} width='100%' alt='About Us' /></Col>
          </Row>
          <Row className="AboutUs-text">
            <Col>
              <div><h3>Who We Are</h3></div>
              <div>
              We, in Peachtree Print, believe that we all have moments in life that we cherish and will never forget. Among these countless moments of life there are some special ones, which leave a life long impact on you. From your special engagement to wedding day, your birthday or anniversary, the birth of your child, or maybe vacation with your beloved ones. To help you cherish those unforgettable moments and make them last forever, we provide you an exquisite hand made photobooks, photo albums, and variant fancy boxes.
              </div>
            </Col>
            <Col className="AboutUs-right-desc">
              <div>At Peachtree Print, we make photobook for a living. We provide you with your very own customize album and photobook. We put a heavy emphasis into tiny details as each and every one of our product is dedicatedly hand-made by our skilled team of craftsman.</div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}

export default About;
