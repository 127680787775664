import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Advantage from './Advantage';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Inner Pages
import Banner from './pages/Banner';
import Business from './pages/Business';
import Packaging from './pages/Packaging';
import Party from './pages/Party';
import Photo from './pages/Photo';
import Sticker from './pages/Sticker';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Banner />,
  },
  {
    path: "/banner",
    element: <Banner />,
  },
  {
    path: "/business",
    element: <Business />
  },
  {
    path: "/packaging",
    element: <Packaging />
  },
  {
    path: "/party",
    element: <Party />
  },
  {
    path: "/photo",
    element: <Photo />
  },
  {
    path: "/sticker",
    element: <Sticker />
  },
  {
    path: "/about-us",
    element: <AboutUs />
  },
  {
    path: "/contact-us",
    element: <ContactUs />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Advantage />
    <RouterProvider router={router} />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
